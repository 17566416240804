import React from "react"
import ReactWOW from "react-wow"
import { Link } from "gatsby"
import { Button } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

import BorrowHowMuch from "../../assets/images/home-loans/first-home-buyer/icon-borrow.svg"
import FirstTimeGrant from "../../assets/images/home-loans/first-home-buyer/icon-buyer.svg"
import SupportThroughout from "../../assets/images/home-loans/first-home-buyer/icon-support.svg"
import FAQ from "../../assets/images/home-loans/icon-FAQ.svg"
import ChevronRight from "../../assets/images/next.png"
import ForSale from "../../assets/images/home-loans/first-home-buyer/icon-for-sale.svg"

const Banner = () => {
  return (
    <div className="homeloans-hero-banner mb-5">
      <div className="main-banner-wrapper first-time-buyer-color">
        {/* <hr></hr> */}
        <div className="row">
          <div className="col-12 col-md-5 col-xl-5">
            <div className="main-banner-content first-time-buyer-color mb-md-5">
              <img className="mb-3" src={ForSale} alt="img-for-sale" />
              <h1>First Time Home Buyer Guide</h1>
              <h2 className="first-time-buyer-color mt-2">
                Buying your first home is a big and exciting step to take.
              </h2>
              <ReactWOW delay=".05s" animation="fadeInUp">
                <div className="">
                  <a
                    className="default-btn primary-btn btn-lg mt-3 mt-md-4"
                    href="https://oxygen-home-loans.s3.ap-southeast-2.amazonaws.com/web-site/guides/Oxygen+Home+Loans+-+First+Home+Buyers+guide.pdf"
                    target="_blank"
                  >
                    Download Guide Now
                  </a>
                </div>
                <div className="">
                  {`${process.env.GATSBY_ENVIRONMENT}` === "staging" ? (
                    <a
                      href={`${process.env.GATSBY_BACKEND_API_URL}/createSessionOpen/1d9971ea-fc77-4dcc-9fd4-d7d978eaf030`}
                      className="default-btn colored-outline first-time-buyer-color btn-lg mt-3 mt-lg-3 mb-2"
                      target="_blank"
                    >
                      Apply Now<span></span>
                    </a>
                  ) : (
                    <a
                      href={`${process.env.GATSBY_BACKEND_API_URL}/createSessionOpen/1d9971ea-fc77-4dcc-9fd4-d7d978eaf030`}
                      className="default-btn colored-outline first-time-buyer-color btn-lg mt-3 mt-lg-3 mb-2"
                      target="_blank"
                    >
                      Apply Now<span></span>
                    </a>
                  )}
                </div>
              </ReactWOW>
            </div>
          </div>
          <div className="col-12 col-md-7 col-xl-7 d-flex align-items-center">
            <div className="container mt-4">
              {/* <div className="col-12">
              <p>Here's what we can do for you:</p>
            </div> */}
              <div className="row ml-lg-5 pt-5 pt-md-0">
                <div className="col-12">
                  <div
                    className="summary-container d-flex justify-content-start mb-md-5"
                    onClick={() => scrollTo("#borrow", "start")}
                  >
                    <img
                      className="mr-3"
                      src={BorrowHowMuch}
                      alt="icon-how-much-can-borrow"
                    />
                    <div className="flex-column-justify-center">
                      <h4>Find Out How Much Can You Borrow?</h4>
                      <h5 className="why-item-text">
                        We’ll find the right homeloan for you
                      </h5>
                    </div>
                    <img
                      className="chevron-right"
                      src={ChevronRight}
                      alt="chevron right"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="summary-container d-flex justify-content-start mb-md-5"
                    onClick={() => scrollTo("#eligibility", "start")}
                  >
                    <img
                      className="mr-3"
                      src={FirstTimeGrant}
                      alt="icon-first-time-grant"
                    />
                    <div className="flex-column-justify-center">
                      <h4>Grant for First Time Buyers!</h4>
                      <h5 className="why-item-text">
                        Check if you're eligible
                      </h5>
                    </div>
                    <img
                      className="chevron-right"
                      src={ChevronRight}
                      alt="chevron right"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="summary-container d-flex justify-content-start mb-md-5"
                    onClick={() => scrollTo("#support", "start")}
                  >
                    <img
                      className="mr-3"
                      src={SupportThroughout}
                      alt="icon-support throughout"
                    />
                    <div className="flex-column-justify-center">
                      <h4>We Will Support You Throughout</h4>
                      <h5 className="why-item-text">
                        We will be there every step of the way
                      </h5>
                    </div>
                    <img
                      className="chevron-right"
                      src={ChevronRight}
                      alt="chevron right"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="summary-container d-flex justify-content-start"
                    onClick={() => scrollTo("#faq", "start")}
                  >
                    <img className="mr-3" src={FAQ} alt="icon-FAQ" />
                    <div className="flex-column-justify-center">
                      <h4>FAQs</h4>
                      <h5 className="why-item-text">Check our FAQs</h5>
                    </div>
                    <img
                      className="chevron-right"
                      src={ChevronRight}
                      alt="chevron right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
