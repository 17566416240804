import React, { useEffect } from "react"
import ReactWOW from "react-wow"
import { Link } from "gatsby"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/FirstHomeBuyer/Banner.js"
import Footer from "../components/App/Footer"
import CTABanner from "../components/Common/CTABanner"
import SEO from "../components/App/SEO"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion"

const FirstHomeBuyer = () => {
  // Responsive rendering
  // const [width, setWidth] = React.useState(1199)

  // useEffect(() => {
  //   if (typeof window !== `undefined` && width ==undefined) {

  //     // const updateWidthAndHeight = () => {
  //       setWidth(1000)

  //   // }
  //   console.log("width", window.innerWidthth);
  // }
  // });

  return (
    <Layout>
      <SEO
        title="First Home Buyer Loan | Oxygen - Home Loans Made Simple"
        description="At Oxygen we have a very simple value proposition. Provide our customers with a higher quality of advice, the most competitive loan, and do it hassle free."
      />
      <Navbar />
      <PageBanner />

      {/* How much can you borrow? */}
      <section id="borrow">
        <div className="plain-section-wrapper pt-2 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 pt-4 pb-4">
                <h3 className="h3-careers">How Much Can You Borrow?</h3>
                <p>
                  Buying your first home is an exciting, but a big step to take
                  and one that comes with many questions and decisions. The
                  first big question is how much you can borrow and what your
                  likely repayments will be.
                </p>
                <p>
                  That’s where we can help, we’ll do the legwork for you. We can
                  compare home loans across a variety of products available from
                  Australia’s leading lending institutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Grant for First Time Buyers! */}
      <section id="eligibility">
        <div className="blue-section-wrapper pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 pt-4 pb-4">
                <h3 className="h3-careers">Grant for First Time Buyers!</h3>
                <p>
                  Being a first home buyer, you may be eligible for a first home
                  buyer grant. This grant may be available to Australian
                  citizens or permanent residents who wish to buy or build their
                  first home, which will be their principal place of residence
                  within 12 months of settlement.
                </p>
                <p>
                  As grant conditions vary from state to state,{" "}
                  <Link to="/contact" className="underlined-link link">
                    contact us
                  </Link>{" "}
                  to find out more about eligibility requirements in your state
                  and how much grant money you could receive.
                </p>
                <p>
                  As a guide, this{" "}
                  <a
                    href="https://www.firsthome.gov.au/"
                    target="_blank"
                    class="underlined-link"
                  >
                    government site
                  </a>{" "}
                  is also good to reference point.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  We Will Support You Throughout */}
      <section id="support">
        <div className="teal-section-wrapper pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 pt-4 pb-4">
                <h3 className="h3-careers">We Will Support You Throughout</h3>
                <p>
                  We will also liaise with the lender. It’s our job to do the
                  hard work and you can focus on finding the right home for you.
                  We’ll be there every step of the way to guide you through the
                  entire home loan process – from application to approval.
                </p>
              </div>
            </div>
            <ReactWOW delay=".05s" animation="fadeInUp">
              <div className="row d-flex justify-content-center pb-5">
                <div className="col-12 col-md-5 col-lg-4">
                  <a
                    className="default-btn navy-btn-solid btn-lg mt-2 mt-md-0"
                    href="https://oxygen-home-loans.s3.ap-southeast-2.amazonaws.com/web-site/guides/Oxygen+Home+Loans+-+First+Home+Buyers+guide.pdf"
                    target="_blank"
                  >
                    Download Guide Now
                  </a>
                </div>
                <div className="col-12 col-md-5 col-lg-4">
                  {`${process.env.GATSBY_ENVIRONMENT}` === "staging" ? (
                    <a
                      href={`${process.env.GATSBY_BACKEND_API_URL}/createSessionOpen/1d9971ea-fc77-4dcc-9fd4-d7d978eaf030`}
                      className="default-btn navy-btn-solid light-blue-btn-outline-solid btn-lg mt-3 mt-md-0"
                      target="_blank"
                    >
                      Apply Now<span></span>
                    </a>
                  ) : (
                    <a
                      href={`${process.env.GATSBY_BACKEND_API_URL}/createSessionOpen/1d9971ea-fc77-4dcc-9fd4-d7d978eaf030`}
                      className="default-btn navy-btn-solid light-blue-btn-outline-solid btn-lg mt-3 mt-md-0"
                      target="_blank"
                    >
                      Apply Now<span></span>
                    </a>
                  )}
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </section>

      {/* FAQ Guide - First Home Buyers */}
      <section id="faq">
        <div className="plain-section-wrapper faq-dropdown-wrapper ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="h3-careers">FAQ Guide - First Home Buyers</h3>
                <div className="faq-accordion mt-4 mt-md-5">
                  {/* {width > 1199 ? ( */}
                  <Accordion preExpanded={["a", "b", "c", "d", "e", "f", "g"]}>
                    <AccordionItem uuid="a">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q1. How much money can I borrow?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          We’re all unique when it comes to our finances and
                          borrowing needs. Contact us today, we can help with
                          calculations based on your circumstances
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="b">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q2. How do I choose a loan that’s right for me?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          Our guides to loan types and features will help you
                          learn about the main options available. There are
                          hundreds of different home loans available, so talk to
                          us today.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="c">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q3. How much do I need for a deposit?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          Usually between 5% – 10% of the value of a property,
                          which you pay when signing a Contract of Sale. Speak
                          with us to discuss your options for a deposit. You may
                          be able to borrow against the equity in your existing
                          home or an investment property.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="d">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q4. How much will regular repayments be?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          Go to our{" "}
                          <Link
                            to="/calculators/loan-repayment-calculator"
                            className="underlined-link link"
                          >
                            Repayment Calculator
                          </Link>{" "}
                          for an estimate. Because there so many different loan
                          products, some with lower introductory rates, talk to
                          us today about the deals currently available, we’ll
                          work with you to find a loan set-up that’s right for
                          you.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="e">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q5. How often do I make home loan repayments — weekly,
                          fortnightly or monthly?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          Most lenders offer flexible repayment options to suit
                          your pay cycle. Aim for weekly or fortnightly
                          repayments, instead of monthly, as you will make more
                          payments in a year, which will shave dollars and time
                          off your loan.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem uuid="f">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q6. What is the First Home Owner Grant and can I get
                          one?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          This is a grant available to Australian citizens or
                          permanent residents who wish to buy or build their
                          first home, which will be their principal place of
                          residence within 12 months of settlement.{" "}
                          <Link to="/contact" className="underlined-link link">
                            Contact us directly
                          </Link>{" "}
                          to find out more about eligibility requirements in
                          your state and how much grant money you could receive.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem uuid="g">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q7. What fees/costs should I budget for?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3">
                          There are a number of fees and costs involved when
                          buying a property. To help avoid any surprises, the
                          list below sets out many of the usual costs:
                        </p>
                        <ul className="mb-3">
                          <li>
                            <span className="main-point">Stamp duty</span> —
                            This is the big one. All other costs are relatively
                            small by comparison. Stamp duty rates vary between
                            state and territory governments and also depend on
                            the value of the property you buy. You may also have
                            to pay stamp duty on the mortgage itself. To
                            estimate your possible stamp duty charge, visit our{" "}
                            <Link
                              to="/calculators/stamp-duty-calculator"
                              className="underlined-link link"
                            >
                              Stamp Duty Calculator.
                            </Link>
                          </li>
                          <li>
                            <span className="main-point">
                              Legal/conveyancing fees
                            </span>{" "}
                            — Generally around $1,000 – $1500, these fees cover
                            all the legal requirements around your property
                            purchase, including title searches.
                          </li>
                          <li>
                            <span className="main-point">
                              Building inspection
                            </span>{" "}
                            — This should be carried out by a qualified expert,
                            such as a structural engineer, before you purchase
                            the property. Your Contract of Sale should be
                            subject to the building inspection, so if there are
                            any structural problems you have the option to
                            withdraw from the purchase without any significant
                            financial penalties. A building inspection and
                            report can cost up to $1,000, depending on the size
                            of the property. Your conveyancer will usually
                            arrange this inspection, and you will usually pay
                            for it as part of their total invoice at settlement
                            (in addition to the conveyancing fees).
                          </li>
                          <li>
                            <span className="main-point">Pest inspection</span>{" "}
                            — Also to be carried out before purchase to ensure
                            the property is free of problems, such as white
                            ants. Your Contract of Sale should be subject to the
                            pest inspection, so if any unwanted crawlies are
                            found you may have the option to withdraw from the
                            purchase without any significant financial
                            penalties. Allow up to $500 depending on the size of
                            the property. Your real estate agent or conveyancer
                            may arrange this inspection, and you will usually
                            pay for it as part of their total invoice at
                            settlement (in addition to the conveyancing fees).
                          </li>
                          <li>
                            <span className="main-point">Lender costs</span> —
                            Most lenders charge establishment fees to help cover
                            the costs of their own valuation as well as
                            administration fees. We will let you know what your
                            lender charges but allow about $600 to $800.
                          </li>
                          <li>
                            <span className="main-point">Moving costs</span> —
                            Don’t forget to factor in the cost of a removalist
                            if you plan on using one.
                          </li>
                          <li>
                            <span className="main-point">
                              Mortgage Insurance costs
                            </span>{" "}
                            — If you borrow more than 80% of the purchase price
                            of the property, you’ll also need to pay Lender
                            Mortgage Insurance. You may also consider whether to
                            take out Mortgage Protection Insurance. If you buy a
                            strata title, regular strata fees are payable.
                          </li>
                          <li>
                            <span className="main-point">Ongoing costs</span> —
                            You will need to include council and water rates
                            along with regular loan repayments. It is important
                            to also consider building insurance and contents
                            insurance. Your lender will probably require a
                            minimum sum insured for the building to cover the
                            loan.
                          </li>
                        </ul>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                  {/* ) : (
                    <Accordion allowZeroExpanded>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Q1. How much money can I borrow?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3 mb-3">
                            We’re all unique when it comes to our finances and
                            borrowing needs. Contact us today, we can help with
                            calculations based on your circumstances
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Q2. How do I choose a loan that’s right for me?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3 mb-3">
                            Our guides to loan types and features will help you
                            learn about the main options available. There are
                            hundreds of different home loans available, so talk
                            to us today.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Q3. How much do I need for a deposit?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3 mb-3">
                            Usually between 5% – 10% of the value of a property,
                            which you pay when signing a Contract of Sale. Speak
                            with us to discuss your options for a deposit. You
                            may be able to borrow against the equity in your
                            existing home or an investment property.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Q4. How much will regular repayments be?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3 mb-3">
                            Go to our{" "}
                            <Link
                              to="/calculators/loan-repayment-calculator"
                              className="underlined-link link"
                            >
                              Repayment Calculator
                            </Link>{" "}
                            for an estimate. Because there so many different
                            loan products, some with lower introductory rates,
                            talk to us today about the deals currently
                            available, we’ll work with you to find a loan set-up
                            that’s right for you.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Q5. How often do I make home loan repayments —
                            weekly, fortnightly or monthly?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3 mb-3">
                            Most lenders offer flexible repayment options to
                            suit your pay cycle. Aim for weekly or fortnightly
                            repayments, instead of monthly, as you will make
                            more payments in a year, which will shave dollars
                            and time off your loan.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Q6. What is the First Home Owner Grant and can I get
                            one?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3 mb-3">
                            This is a grant available to Australian citizens or
                            permanent residents who wish to buy or build their
                            first home, which will be their principal place of
                            residence within 12 months of settlement.{" "}
                            <Link
                              to="/contact"
                              className="underlined-link link"
                            >
                              Contact us directly
                            </Link>{" "}
                            to find out more about eligibility requirements in
                            your state and how much grant money you could
                            receive.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Q7. What fees/costs should I budget for?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3">
                            There are a number of fees and costs involved when
                            buying a property. To help avoid any surprises, the
                            list below sets out many of the usual costs:
                          </p>
                          <ul className="mb-3">
                            <li>
                              <span className="main-point">Stamp duty</span> —
                              This is the big one. All other costs are
                              relatively small by comparison. Stamp duty rates
                              vary between state and territory governments and
                              also depend on the value of the property you buy.
                              You may also have to pay stamp duty on the
                              mortgage itself. To estimate your possible stamp
                              duty charge, visit our{" "}
                              <Link
                                to="/calculators/stamp-duty-calculator"
                                className="underlined-link link"
                              >
                                Stamp Duty Calculator.
                              </Link>
                            </li>
                            <li>
                              <span className="main-point">
                                Legal/conveyancing fees
                              </span>{" "}
                              — Generally around $1,000 – $1500, these fees
                              cover all the legal requirements around your
                              property purchase, including title searches.
                            </li>
                            <li>
                              <span className="main-point">
                                Building inspection
                              </span>{" "}
                              — This should be carried out by a qualified
                              expert, such as a structural engineer, before you
                              purchase the property. Your Contract of Sale
                              should be subject to the building inspection, so
                              if there are any structural problems you have the
                              option to withdraw from the purchase without any
                              significant financial penalties. A building
                              inspection and report can cost up to $1,000,
                              depending on the size of the property. Your
                              conveyancer will usually arrange this inspection,
                              and you will usually pay for it as part of their
                              total invoice at settlement (in addition to the
                              conveyancing fees).
                            </li>
                            <li>
                              <span className="main-point">
                                Pest inspection
                              </span>{" "}
                              — Also to be carried out before purchase to ensure
                              the property is free of problems, such as white
                              ants. Your Contract of Sale should be subject to
                              the pest inspection, so if any unwanted crawlies
                              are found you may have the option to withdraw from
                              the purchase without any significant financial
                              penalties. Allow up to $500 depending on the size
                              of the property. Your real estate agent or
                              conveyancer may arrange this inspection, and you
                              will usually pay for it as part of their total
                              invoice at settlement (in addition to the
                              conveyancing fees).
                            </li>
                            <li>
                              <span className="main-point">Lender costs</span> —
                              Most lenders charge establishment fees to help
                              cover the costs of their own valuation as well as
                              administration fees. We will let you know what
                              your lender charges but allow about $600 to $800.
                            </li>
                            <li>
                              <span className="main-point">Moving costs</span> —
                              Don’t forget to factor in the cost of a removalist
                              if you plan on using one.
                            </li>
                            <li>
                              <span className="main-point">
                                Mortgage Insurance costs
                              </span>{" "}
                              — If you borrow more than 80% of the purchase
                              price of the property, you’ll also need to pay
                              Lender Mortgage Insurance. You may also consider
                              whether to take out Mortgage Protection Insurance.
                              If you buy a strata title, regular strata fees are
                              payable.
                            </li>
                            <li>
                              <span className="main-point">Ongoing costs</span>{" "}
                              — You will need to include council and water rates
                              along with regular loan repayments. It is
                              important to also consider building insurance and
                              contents insurance. Your lender will probably
                              require a minimum sum insured for the building to
                              cover the loan.
                            </li>
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default FirstHomeBuyer
